.section-links__container ~ .section-links__container {
  margin-top: 80px;
}
.section-links__title {
  position: relative;
  max-width: 100%;
  padding-bottom: 16px;
}
.section-links__title h2 {
  color: #262626;
}
.section-links__link-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px 40px;
  padding-top: 43px;
  border-top: 4px solid var(--red-main);
}
.section-links__link-item {
  border: none;
}
.section-links__item-link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
  background-color: #F4F4F4;
  transition: 0.35s;
}
.section-links__item-link:hover,
.section-links__item-link:focus {
  background-color: #fff;
  box-shadow: 0px 6px 20px 6px rgba(0, 0, 0, 0.14);
}
.section-links__item-link:hover:before,
.section-links__item-link:focus:before {
  background-color: var(--red-main);
}
.section-links__item-link:hover:after,
.section-links__item-link:focus:after {
  border-color: #fff;
}
.section-links__item-link:before,
.section-links__item-link:after {
  position: absolute;
  z-index: 1;
  content: '';
  cursor: pointer;
  transition: 0.35s;
  transform: translateY(-50%);
}
.section-links__item-link:before {
  right: 24px;
  top: 50%;
  width: 41px;
  height: 41px;
  background: #D9D9D9;
  border-radius: 11px;
}
.section-links__item-link:after {
  right: 41px;
  top: 45%;
  border: solid #000;
  border-width: 0 2px 2px 0;
  width: 12px;
  height: 12px;
  transform: rotate(-45deg);
}
.section-links__item-img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 15px;
  width: 90px;
  height: 90px;
  background-color: rgba(217, 217, 217, 0.5);
}
.section-links__item-img {
  pointer-events: none;
  position: relative;
  z-index: 0;
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: center;
}
.section-links__item-name {
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: 500;
  font-family: var(--font-roboto);
  color: #262626;
  font-size: 22px;
  line-height: 1.16;
  padding-left: 20px;
  border-left: 1px solid #BABABA;
  height: 76px;
  margin-left: 20px;
  max-width: 243px;
}
@media (max-width: 1600px) {
  .section-links__container .section-links__title {
    padding-bottom: 0;
  }
  .section-links__link-list {
    grid-gap: 30px 30px;
  }
}
@media (max-width: 1530px) {
  .section-links__link-list {
    grid-gap: 20px 20px;
  }
  .section-links__item-name {
    font-size: 20px;
    max-width: 200px;
    margin-left: 15px;
    padding-left: 15px;
  }
}
@media (max-width: 1400px) {
  .section-links__link-list {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px 30px;
  }
  .section-links__item-name {
    font-size: 24px;
    max-width: 243px;
    margin-left: 20px;
  }
}
@media (max-width: 1023px) {
  .section-links__link-list {
    grid-gap: 20px 20px;
  }
  .section-links__item-name {
    font-size: 20px;
    max-width: 200px;
    padding-left: 15px;
    margin-left: 15px;
  }
}
@media (max-width: 950px) {
  .section-links__link-list {
    grid-gap: 15px 15px;
  }
  .section-links__item-link {
    padding: 15px;
  }
  .section-links__item-link:before {
    right: 19px;
  }
  .section-links__item-link:after {
    right: 36px;
  }
  .section-links__item-name {
    font-size: 18px;
    max-width: 180px;
  }
}
@media (max-width: 850px) {
  .section-links__link-list {
    grid-gap: 20px 20px;
  }
  .section-links__link-list {
    padding-top: 24px;
  }
  .section-links__item-link {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    height: 100%;
  }
  .section-links__item-link:before,
  .section-links__item-link:after {
    display: none;
  }
  .section-links__item-img-wrap {
    width: 72px;
    height: 72px;
  }
  .section-links__item-img {
    width: 44px;
    height: 44px;
  }
  .section-links__item-name {
    font-size: 16px;
    max-width: 100%;
    padding-left: 0;
    border-left: none;
    margin-top: 20px;
    height: max-content;
    padding-top: 20px;
    margin-left: 0;
    width: 100%;
    border-top: 1px solid #BABABA;
  }
  .section-links__item-name br {
    display: none;
  }
}
@media (max-width: 576px) {
  .section-links__container ~ .section-links__container {
    margin-top: 27px;
  }
  .section-links__link-list {
    display: none;
    grid-gap: 0;
  }
  .section-links__list.mobile {
    position: relative;
    padding-top: 24px;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
    border-top: none;
  }
  .section-links__list.mobile:before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    background-color: var(--red-main);
    transform: translateX(-50%);
    width: calc(100% - 32px);
    height: 4px;
  }
  .section-links__list.mobile .section-links__link-item {
    border: none;
  }
  .section-links__list.mobile .section-links__link-item:not(:last-child) {
    margin-bottom: 20px;
  }
  .section-links__list.mobile .swiper-wrapper {
    margin-bottom: 20px;
  }
}
