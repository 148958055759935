.section-links {
    &__container {
        &~& {
            margin-top: 80px;
        }
    }

    &__title {
        position: relative;
        max-width: 100%;
        padding-bottom: 16px;

        h2 {
            color: #262626;
        }
    }

    &__link-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 40px 40px;
        padding-top: 43px;
        border-top: 4px solid var(--red-main);
    }

    &__link-item {
        border: none;
    }

    &__item-link {
        position: relative;
        display: flex;
        align-items: center;
        padding: 20px;
        border-radius: 5px;
        background-color: #F4F4F4;
        transition: .35s;

        &:hover,
        &:focus {
            background-color: #fff;
            box-shadow: 0px 6px 20px 6px rgba(0, 0, 0, 0.14);

            &:before {
                background-color: var(--red-main);
            }

            &:after {
                border-color: #fff;
            }
        }

        &:before,
        &:after {
            position: absolute;
            z-index: 1;
            content: '';
            cursor: pointer;
            transition: .35s;
            transform: translateY(-50%);
        }

        &:before {
            right: 24px;
            top: 50%;
            width: 41px;
            height: 41px;
            background: #D9D9D9;
            border-radius: 11px;
        }

        &:after {
            right: 41px;
            top: 45%;
            border: solid #000;
            border-width: 0 2px 2px 0;
            width: 12px;
            height: 12px;
            transform: rotate(-45deg);
        }
    }

    &__item-img-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 15px;
        width: 90px;
        height: 90px;
        background-color: rgba(217, 217, 217, 0.5);
    }

    &__item-img {
        pointer-events: none;
        position: relative;
        z-index: 0;
        width: 60px;
        height: 60px;
        object-fit: contain;
        object-position: center;
    }

    &__item-name {
        display: flex;
        align-items: center;
        text-align: left;
        font-weight: 500;
        font-family: var(--font-roboto);
        color: #262626;
        font-size: 22px; 
        line-height: 1.16;
        padding-left: 20px;
        border-left: 1px solid #BABABA;
        height: 76px;
        margin-left: 20px;
        max-width: 243px;
    }
}

@media(max-width: 1600px) {
    .section-links {
        &__container {
            .section-links__title {
                padding-bottom: 0;
            }
        }

        &__link-list {
            grid-gap: 30px 30px;
        }
    }
}

@media(max-width: 1530px) {
    .section-links {
        &__link-list {
            grid-gap: 20px 20px;
        }

        &__item-name {
            font-size: 20px;
            max-width: 200px;
            margin-left: 15px;
            padding-left: 15px;
        }
    }
}

@media(max-width: 1400px) {
    .section-links {
        &__link-list {
            grid-template-columns: 1fr 1fr;
            grid-gap: 30px 30px;
        }

        &__item-name {
            font-size: 24px;
            max-width: 243px;
            margin-left: 20px;
        }
    }
}

@media(max-width: 1023px) {
    .section-links {
        &__link-list {
            grid-gap: 20px 20px;
        }

        &__item-name {
            font-size: 20px;
            max-width: 200px;
            padding-left: 15px;
            margin-left: 15px;
        }
    }
}

@media(max-width: 950px) {
    .section-links {
        &__link-list {
            grid-gap: 15px 15px;
        }

        &__item-link {
            padding: 15px;

            &:before {
                right: 19px;
            }

            &:after {
                right: 36px;
            }
        }

        &__item-name {
            font-size: 18px;
            max-width: 180px;
        }
    }
}

@media(max-width: 850px) {
    .section-links {
        &__link-list {
            grid-gap: 20px 20px;
        }

        &__link-list {
            padding-top: 24px;
        }

        &__item-link {
            flex-direction: column;
            align-items: flex-start;
            padding: 20px;
            height: 100%;

            &:before,
            &:after {
                display: none;
            }
        }

        &__item-img-wrap {
            width: 72px;
            height: 72px;
        }

        &__item-img {
            width: 44px;
            height: 44px;
        }

        &__item-name {
            font-size: 16px;
            max-width: 100%;
            padding-left: 0;
            border-left: none;
            margin-top: 20px;
            height: max-content;
            padding-top: 20px;
            margin-left: 0;
            width: 100%;
            border-top: 1px solid #BABABA;

            br {
                display: none;
            }
        }
    }
}

@media(max-width: 576px) {
    .section-links {
        &__container {
            &~& {
                margin-top: 27px;
            }
        }

        &__link-list {
            display: none;
            grid-gap: 0;
        }

        &__list.mobile {
            position: relative;
            padding-top: 24px;
            margin-left: -16px;
            margin-right: -16px;
            padding-left: 16px;
            padding-right: 16px;
            border-top: none;

            &:before {
                position: absolute;
                content: '';
                top: 0;
                left: 50%;
                background-color: var(--red-main);
                transform: translateX(-50%);
                width: calc(100% - 32px);
                height: 4px;
            }

            .section-links {
                &__link-item {
                    border: none;

                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }
                }
            }

            .swiper-wrapper {
                margin-bottom: 20px;
            }
        }
    }
}